import { t } from '@lingui/macro'
import { authRoles } from 'app/auth/authRoles'
import { EgretLoadable } from 'egret'
import { myI18n } from 'translation/I18nConnectedProvider'

const dmahRoutes = [
  {
    path: '/dmah',
    component: EgretLoadable({
      loader: () => import('./DMAHApplications')
    }),
    auth: authRoles.DMAH,
    isTranslatable: true,
    label: myI18n._(t`DMAH_TAB`)
  }
]

export default dmahRoutes
