import { t } from "@lingui/macro";
import { Text, View } from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import { myI18n } from "translation/I18nConnectedProvider";
import { pdfDefaultFontSize } from "./Common";

type FormElementTitlePDFProps = {
  index: number;
  parseAsHtml?: boolean;
  parseHelpTextAsHtml?: boolean;
  required?: boolean;
  showNumering?: boolean;
  title: React.ReactNode;
  helpText?: React.ReactNode;
};

export const FormElementTitlePDF = ({
  index,
  parseAsHtml,
  parseHelpTextAsHtml,
  required,
  helpText,
  showNumering,
  title,
}: FormElementTitlePDFProps) => {
  console.log(parseAsHtml);
  return (
    <>
      <Text
        style={{
          fontSize: 10,
          fontWeight: "bold",
          marginBottom: 8,
        }}
      >
        {showNumering && index + ". "}
        {parseAsHtml ? (
          <Html
            collapse={false}
            style={{ fontSize: pdfDefaultFontSize, backgroundColor: "red" }}
          >
            {String(title)}
          </Html>
        ) : (
          title
        )}
        {required && ` (${myI18n._(t`Required`)})`}
      </Text>
      {helpText ? (
        <View>
          <Text
            style={{
              fontSize: pdfDefaultFontSize - 2,
              fontStyle: "italic",
              marginBottom: 8,
            }}
          >
            {parseHelpTextAsHtml ? (
              <Html
                collapse={false}
                style={{ fontSize: pdfDefaultFontSize - 2 }}
              >
                {String(helpText)}
              </Html>
            ) : (
              helpText
            )}
          </Text>
        </View>
      ) : (
        <View />
      )}
    </>
  );
};
